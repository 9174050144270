const NeedRecaptchaInterfaces = [
  '/identity/sessions',
  '/identity/third_party_sessions',
  '/identity/users/forget_password',
  '/identity/registrations/resend_active_email',
  '/identity/email_auth_tokens/send_code',
  '/identity/users/update_profile',
  '/identity/subscribe_emails',
  '/identity/registrations/destroy_user',
  '/identity/registrations',
  '/identity/registrations/auto',
  '/identity/users/reset_password',
  '/identity/users/change_password',
  '/identity/user/validate_email',
  '/identity/registrations/quick',
  '/identity/quick_logins/login_token',
  '/.netlify/functions/privacy-request',
  '/api/password-signin',
  '/api/auto-signup',
];
module.exports = NeedRecaptchaInterfaces;
