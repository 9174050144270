import { navigateTo, removeUserInfo, saveUserInfo } from '@/utils/helper';
import { getAdCookie, getApp, getRecaptchaHeaders } from '@/utils';
import { stringify } from 'query-string';
import { CountryMap } from '@/utils/constants';
import { getAppContextValue } from '@/components/AppProvider/AppProviderContext';

/*
 * 用户中心相关接口
 */

function createResponseError(response, errorData) {
  const error = new Error(errorData?.error?.message || errorData?.error || response.statusText);
  error.type = 'responseError';
  error.response = response;
  error.data = errorData;
  return error;
}

async function unActive({ email, result, location }) {
  sessionStorage.setItem('sent-code-email', email);
  if (result.err_code === 'no_active_and_pass') {
    await navigateTo(`/after-register${location.search}`);
  } else if (result.validate_token) {
    await navigateTo(`/sent-code${location.search ? `${location.search}&` : '?'}type=validate`);
  } else {
    await navigateTo(`/sent-code${location.search ? `${location.search}&` : '?'}type=register`);
  }
}

// 检查账号是否已经注册
export const validateEmail = async ({ location, email }) => {
  const recaptchaHeaders = await getRecaptchaHeaders('user_info');
  const response = await fetch('/identity/users/validate_email', {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body: JSON.stringify({
      ...getAdCookie(location),
      email,
    }),
  });

  const result = await response.json();

  if (response.ok && result) {
    return result.data.status;
  }
  throw createResponseError(response, result);
};

export const resendActiveEmail = async ({ location, email, quick }) => {
  const recaptchaHeaders = await getRecaptchaHeaders('register');
  const response = await fetch('/identity/registrations/resend_active_email', {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body: JSON.stringify({
      ...getAdCookie(location),
      email,
      quick,
    }),
  });
  const result = await response.json();

  if (response.ok && result) {
    return 'success';
  }
  throw createResponseError(response, result);
};

export const login = async ({ location, email, password, access_token, third_party }) => {
  const { app } = getApp(location);
  removeUserInfo(app);
  const bodyData = {
    ...getAdCookie(location),
    email,
    password,
    access_token,
    third_party,
  };

  const body = JSON.stringify(bodyData);

  const thirdPrefix = third_party ? 'third_party_' : '';
  const recaptchaHeaders = await getRecaptchaHeaders(`${thirdPrefix}sessions`);
  const response = await fetch(`/identity/${thirdPrefix}sessions`, {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();

  if (response.ok && result.data) {
    saveUserInfo(app, result.data);
    return true;
  }

  if (result.error_code === '105') {
    await unActive({ email, result, location });
    return false;
  }

  throw createResponseError(response, result);
};

export const quickLogin = async ({ location, email, data = '' }) => {
  const bodyData = {
    ...getAdCookie(location),
    email,
    sign: 'email',
    data,
  };

  removeUserInfo(getApp(location).app);
  const body = JSON.stringify(bodyData);
  const recaptchaHeaders = await getRecaptchaHeaders('login');
  const response = await fetch(`/identity/quick_logins/login_token`, {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();
  if (response.ok && result.data) {
    return true;
  }

  if (result.error_code === '105') {
    await unActive({ email, result, location });
    return false;
  }

  throw createResponseError(response, result);
};

export const quickLoginWithToken = async ({ location, email, token }) => {
  const bodyData = {
    ...getAdCookie(location),
    login_token: token,
    email,
    sign: 'email',
  };

  const body = JSON.stringify(bodyData);
  const recaptchaHeaders = await getRecaptchaHeaders('login');
  const response = await fetch(`/identity/quick_logins/token_auth`, {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();
  if (response.ok && result.data) {
    return result.data;
  }

  if (result.error_code === '105') {
    await unActive({ email, result, location });
    return false;
  }

  throw createResponseError(response, result);
};

// 账号密码登录、第三方登录；判断用户是否满足不激活登录；
export const loginWithoutActive = async ({ location, email, password, access_token, third_party }) => {
  const { app } = getApp(location);
  removeUserInfo(app);
  const bodyData = {
    ...getAdCookie(location),
    email,
    password,
    access_token,
    third_party,
  };

  const body = JSON.stringify(bodyData);

  const thirdPrefix = third_party ? 'third_party_' : '';
  const recaptchaHeaders = await getRecaptchaHeaders(`${thirdPrefix}sessions`);
  const response = await fetch(`/api/password-signin/${thirdPrefix}sessions`, {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();

  if (response.ok && result.data) {
    saveUserInfo(app, result.data);
    return true;
  }

  if (result.error_code === '105') {
    await unActive({ email, result, location });
    return false;
  }

  throw createResponseError(response, result);
};

export const register = async ({ location, email, password, is_subscribe }) => {
  const { shopify } = getApp(location);

  const body = JSON.stringify({
    ...getAdCookie(location),
    email,
    password,
    is_subscribe,
    country_code: (CountryMap[shopify.country] || shopify.country).toUpperCase(),
  });
  const recaptchaHeaders = await getRecaptchaHeaders('register');
  const response = await fetch('/identity/registrations', { method: 'POST', headers: { ...recaptchaHeaders }, body });
  const result = await response.json();
  if (response.ok || result.error_code === '105') {
    await unActive({ email, result, location });
    return 'success';
  }
  throw createResponseError(response, result);
};

export const registerQuick = async ({ data, location, email, is_subscribe }) => {
  const { shopify } = getApp(location);

  const bodyData = {
    ...getAdCookie(location),
    email,
    data,
    is_subscribe,
    country_code: (CountryMap[shopify.country] || shopify.country).toUpperCase(),
  };

  const body = JSON.stringify(bodyData);
  const recaptchaHeaders = await getRecaptchaHeaders('register');
  const response = await fetch(`/identity/registrations/quick`, {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();
  if (response.ok && result.data) {
    return true;
  }

  if (result.error_code === '105') {
    await unActive({ email, result, location });
    return false;
  }

  throw createResponseError(response, result);
};

export const registerAuto = async ({ location, email, is_subscribe }) => {
  const { shopify, app } = getApp(location);

  const bodyData = {
    ...getAdCookie(location),
    email,
    is_subscribe,
    country_code: (CountryMap[shopify.country] || shopify.country).toUpperCase(),
  };

  const body = JSON.stringify(bodyData);
  const recaptchaHeaders = await getRecaptchaHeaders('register');
  const response = await fetch(`/api/auto-signup`, {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();

  removeUserInfo(app);

  if (response.ok && result.data) {
    return result.data;
  }

  if (result.error_code === '105') {
    await unActive({ email, result, location });
    return undefined;
  }

  throw createResponseError(result.error, result);
};

export const forgotPassword = async ({ location, email }) => {
  const body = JSON.stringify({
    ...getAdCookie(location),
    email,
  });
  const recaptchaHeaders = await getRecaptchaHeaders('user_info');
  const response = await fetch('/identity/users/forget_password', {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();
  if (response.ok) {
    return result.data;
  }
  if (result.error_code === '105') {
    await unActive({ email, result, location });
    return undefined;
  }

  throw createResponseError(response, result);
};

export const resetPassword = async ({ code, token, password, location }) => {
  const body = JSON.stringify({
    ...getAdCookie(location),
    verify_code: code,
    reset_password_token: token,
    password,
  });
  const recaptchaHeaders = await getRecaptchaHeaders('user_info');
  const response = await fetch('/identity/users/reset_password', {
    method: 'PUT',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();
  if (response.ok) {
    // 修改密码成功，跳转回首页
    return result;
  }
  throw createResponseError(response, result);
};

export const activate = async ({ location, code, token }) => {
  const body = JSON.stringify({
    ...getAdCookie(location),
    activation_code: token,
    verify_code: code,
  });
  const recaptchaHeaders = await getRecaptchaHeaders('register');
  const response = await fetch('/identity/registrations/activate', {
    method: 'POST',
    headers: { ...recaptchaHeaders },
    body,
  });
  const result = await response.json();
  if (response.ok) {
    const { app } = getApp(location);
    removeUserInfo(app);
    return result;
  }
  throw createResponseError(response, result);
};

export const fetchProfile = async (data = {}) => {
  const response = await fetch(`/identity/users/profile?${stringify(data)}&_=${Date.now()}`, {
    headers: {},
  });
  const result = await response.json();
  if (response.ok) {
    saveUserInfo(getApp().app, result.data);
    return result.data;
  }

  throw createResponseError(response, result);
};

export const putProfile = async (body = {}) => {
  const recaptchaHeaders = await getRecaptchaHeaders('user_info');
  const response = await fetch(`/identity/users/update_profile`, {
    method: 'PUT',
    headers: {
      ...recaptchaHeaders,
    },
    body: JSON.stringify(body),
  });
  const result = await response.json();
  if (response.ok) {
    saveUserInfo(getApp().app, result.data);
    return result.data;
  }

  throw createResponseError(response, result);
};

// start_ai_generated
export const changePassword = async (body = {}) => {
  const recaptchaHeaders = await getRecaptchaHeaders('user_info');
  const response = await fetch(`/identity/users/change_password`, {
    method: 'PUT',
    headers: {
      ...recaptchaHeaders,
    },
    body: JSON.stringify(body),
  });
  const result = await response.json();
  if (response.ok) {
    return result.data;
  }

  throw createResponseError(response, result);
};

// end_ai_generated

export const sendValidationEmail = async (body) => {
  const recaptchaHeaders = await getRecaptchaHeaders('user_info');

  const headers = {
    ...recaptchaHeaders,
  };

  const response = await fetch(`/identity/users/send_validation_email`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });
  const result = await response.json();
  if (response.ok) {
    return result.data;
  }

  throw createResponseError(response, result);
};

export const emailValidation = async (body) => {
  const recaptchaHeaders = await getRecaptchaHeaders('user_info');
  const response = await fetch(`/identity/users/email_validation`, {
    method: 'POST',
    headers: {
      ...recaptchaHeaders,
    },
    body: JSON.stringify(body),
  });
  const result = await response.json();
  if (response.ok) {
    return result.data;
  }

  throw createResponseError(response, result);
};
